import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledCircle = styled(forwardRef(({ gradient, size, rotate, top, bottom, left, right, ...props }, ref) => <div ref={ref} {...props} />))`
  position: absolute;
  border-radius: 50%;
  opacity: 0.1;
  z-index: -1;

  ${({ gradient }) =>
    gradient?.from &&
    gradient?.to &&
    css`
      background: ${`linear-gradient(0deg, ${gradient.from} 0%, ${gradient.to} 100%);`};
    `}

  ${({ top }) =>
    typeof top !== 'undefined' &&
    css`
      top: ${typeof top === 'string' ? top : `${top}px`};
    `}

  ${({ bottom }) =>
    typeof bottom !== 'undefined' &&
    css`
      bottom: ${typeof bottom === 'string' ? bottom : `${bottom}px`};
    `}

  ${({ left }) =>
    typeof left !== 'undefined' &&
    css`
      left: ${typeof left === 'string' ? left : `${left}px`};
    `}

  ${({ right }) =>
    typeof right !== 'undefined' &&
    css`
      right: ${typeof right === 'string' ? right : `${right}px`};
    `}

  ${({ rotate }) =>
    rotate &&
    css`
      transform: ${`rotate(${rotate}deg)`};
    `}

  ${({ size }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
    `}

  @media ${theme.devices.tablet} {
    display: none;
  }
`;

const Circle = ({ gradient, size, rotate, top, bottom, left, right }) => (
  <StyledCircle
    gradient={gradient}
    size={size}
    rotate={rotate}
    top={top}
    bottom={bottom}
    left={left}
    right={right}
  />
);

Circle.propTypes = {
  gradient: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  size: PropTypes.number,
  rotate: PropTypes.number,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Circle;
