import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '_constants/theme';
import Icon from '_components/atomic/Icon';

const { colors, devices, fontSizes, spaces } = theme;

const StyledContainer = styled.div`
  width: 100%;
  max-width: 767px;
  padding: ${spaces.large4X}px;
  gap: ${spaces.large4X}px;
  background-color: ${colors.white};
  box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  .headerRight {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: ${fontSizes.medium}px;
    line-height: 150%;
    color: ${colors.gray[700]};
    margin-bottom: ${spaces.medium}px;
  }

  .listRightHome {
    width: 100%;
    gap: ${spaces.medium}px;
    display: flex;
    flex-direction: column;
  }
  @media ${devices.tablet} {
    border-radius: 0px;
    padding: 0;
    gap: ${spaces.large2X}px;
    .headerRight {
      text-align: center;
      font-size: ${fontSizes.regular}px;
      margin-bottom: ${spaces.smallX}px;
      padding-left: ${spaces.regular}px;
      padding-right: ${spaces.regular}px;
    }
    .listRightHome {
      padding: ${spaces.regular}px;
      background: ${colors.white};
    }
  }
`;

const StyledItem = styled.div`
  display: flex;
  gap: ${spaces.medium}px;
  background: ${({ color }) => color};
  border-radius: 16px;
  padding: ${spaces.medium}px;
  flex-direction: row;
  align-items: flex-start;
  &:hover {
    background: ${({ textColor }) => textColor};
    .header {
      color: ${colors.white};
    }
    .description {
      color: ${colors.white};
    }
  }
  svg {
    width: 70px;
    height: 70px;
  }
  .header {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: ${fontSizes.large2X}px;
    line-height: 150%;
    color: ${({ textColor }) => textColor};
  }
  .description {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 400;
    font-size: ${fontSizes.medium}px;
    line-height: 150%;
    color: ${colors.gray[700]};
  }
  @media ${devices.tablet} {
    svg {
      width: 40px;
      height: 40px;
    }
    .header {
      font-size: ${fontSizes.medium}px;
    }
    .description {
      font-size: ${fontSizes.regular}px;
    }
  }
`;

const FancyList = ({ headerRight, items }) => {
  return (
    <StyledContainer>
      {headerRight && <div className="headerRight">{headerRight}</div>}
      <div className="listRightHome">
        {items.map((step, idx) => {
          const { title, description, icon, iconPalette, color, textColor } =
            step;
          return (
            <StyledItem key={idx} color={color} textColor={textColor}>
              <div>
                <Icon alias={icon} palette={iconPalette} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="header">{title}</div>
                <div className="description">{description}</div>
              </div>
            </StyledItem>
          );
        })}
      </div>
    </StyledContainer>
  );
};

FancyList.propTypes = {
  headerRight: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      description: PropTypes.node,
      icon: PropTypes.string,
      iconPalette: PropTypes.arrayOf(PropTypes.string),
      color: PropTypes.string,
      textColor: PropTypes.string,
    })
  ),
};

export default FancyList;
