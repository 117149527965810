import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { theme } from '_constants/theme';

// eslint-disable-next-line prettier/prettier, react/display-name
const StyledContainer = styled(forwardRef(({ top, bottom, left, right, ...props }, ref) => <div ref={ref} {...props} />))`
  position: absolute;
  z-index: -1;

  ${({ top }) =>
    typeof top !== 'undefined' &&
    css`
      top: ${typeof top === 'string' ? top : `${top}px`};
    `}

  ${({ bottom }) =>
    typeof bottom !== 'undefined' &&
    css`
      bottom: ${typeof bottom === 'string' ? bottom : `${bottom}px`};
    `}

  ${({ left }) =>
    typeof left !== 'undefined' &&
    css`
      left: ${typeof left === 'string' ? left : `${left}px`};
    `}

  ${({ right }) =>
    typeof right !== 'undefined' &&
    css`
      right: ${typeof right === 'string' ? right : `${right}px`};
    `}

  @media ${theme.devices.tablet} {
    display: none;
  }
`;

const Dots = ({ color, top, bottom, left, right }) => (
  <StyledContainer top={top} bottom={bottom} left={left} right={right}>
    <svg
      width="171"
      height="199"
      viewBox="0 0 171 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="3.88636" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="31.091" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="112.705" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="139.909" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="167.113" cy="3.88636" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="31.091" r="3.88636" fill={color} />
      <circle cx="31.091" cy="31.091" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="31.091" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="31.091" r="3.88636" fill={color} />
      <circle cx="112.705" cy="31.091" r="3.88636" fill={color} />
      <circle cx="139.909" cy="31.091" r="3.88636" fill={color} />
      <circle cx="167.113" cy="31.091" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="31.091" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="112.705" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="139.909" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="167.113" cy="58.2954" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="85.5" r="3.88636" fill={color} />
      <circle cx="31.091" cy="85.5" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="85.5" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="85.5" r="3.88636" fill={color} />
      <circle cx="112.705" cy="85.5" r="3.88636" fill={color} />
      <circle cx="139.909" cy="85.5" r="3.88636" fill={color} />
      <circle cx="167.113" cy="85.5" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="112.705" r="3.88636" fill={color} />
      <circle cx="31.091" cy="112.705" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="112.705" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="112.705" r="3.88636" fill={color} />
      <circle cx="112.705" cy="112.705" r="3.88636" fill={color} />
      <circle cx="139.909" cy="112.705" r="3.88636" fill={color} />
      <circle cx="167.113" cy="112.705" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="139.909" r="3.88636" fill={color} />
      <circle cx="31.091" cy="139.909" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="139.909" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="139.909" r="3.88636" fill={color} />
      <circle cx="112.705" cy="139.909" r="3.88636" fill={color} />
      <circle cx="139.909" cy="139.909" r="3.88636" fill={color} />
      <circle cx="167.113" cy="139.909" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="167.114" r="3.88636" fill={color} />
      <circle cx="31.091" cy="167.114" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="167.114" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="167.114" r="3.88636" fill={color} />
      <circle cx="112.705" cy="167.114" r="3.88636" fill={color} />
      <circle cx="139.909" cy="167.114" r="3.88636" fill={color} />
      <circle cx="167.113" cy="167.114" r="3.88636" fill={color} />
      <circle cx="3.88636" cy="194.318" r="3.88636" fill={color} />
      <circle cx="31.091" cy="194.318" r="3.88636" fill={color} />
      <circle cx="58.2955" cy="194.318" r="3.88636" fill={color} />
      <circle cx="85.5001" cy="194.318" r="3.88636" fill={color} />
      <circle cx="112.705" cy="194.318" r="3.88636" fill={color} />
      <circle cx="139.909" cy="194.318" r="3.88636" fill={color} />
      <circle cx="167.113" cy="194.318" r="3.88636" fill={color} />
    </svg>
  </StyledContainer>
);

Dots.propTypes = {
  color: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Dots;
