import PropTypes from 'prop-types';
import { theme } from '_constants/theme';
import styled, { css } from 'styled-components';

const { colors, devices, fontSizes, spaces } = theme;

const StyledHowSection = styled.div`
  max-width: ${spaces.large24X * 10 + 80}px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .headerSection {
    margin-left: ${spaces.regular}px;
    margin-right: ${spaces.regular}px;
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: ${fontSizes.large11X}px;
    line-height: 130%;
    text-align: center;
    color: ${colors.gray[800]};
  }

  @media ${devices.tablet} {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .headerSection {
      font-size: ${fontSizes.large2X}px;
      line-height: 150%;
    }
  }
`;

const StyledHowContainer = styled.div`
  display: flex;
  gap: ${spaces.large2X - 2}px;
  margin-top: ${spaces.large12X}px;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `}

  @media ${devices.tablet} {
    gap: 0px;
    flex-direction: column;
    margin-top: ${spaces.smallX}px;

    ${({ reverse }) =>
      reverse &&
      css`
        flex-direction: column-reverse;
      `}
  }
`;

const StyledHowLeft = styled.div`
  flex-grow: 1;
  flex-direction: column;
  width: 50%;
  text-align: right;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    min-height: 556px;
    width: 410px;
  }

  .imageHeader {
    text-align: left;
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 150%;
    /* Gray/700 */
    color: #344054;
    max-width: 570px;
    margin-bottom: 37px;
  }

  @media ${devices.tablet} {
    display: none;

    ${({ isLeftMobileView }) =>
      isLeftMobileView &&
      css`
        padding-left: 16px;
        padding-right: 16px;
        display: block;
        width: 100%;
        .imageHeader {
          font-size: 20px;
          text-align: center;
          margin-bottom: 24px;
        }
      `}
  }
`;

const StyledHowRight = styled.div`
  flex-grow: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: ${({ leftImage }) => (leftImage ? 'flex-start' : 'center')};

  ${({ isFullRight }) =>
    isFullRight &&
    css`
      padding: ${spaces.large4X}px;
      gap: ${spaces.large4X}px;
      background-color: ${colors.white};
      box-shadow: 0px 10px 80px rgba(0, 0, 0, 0.05);
      border-radius: 50px;
    `}

  ${({ reverse }) =>
    reverse &&
    css`
      justify-content: center;
    `}

  @media ${devices.tablet} {
    border-radius: 0px;
    padding: 0;
    width: 100%;

    ${({ isFullRight }) =>
      isFullRight &&
      css`
        gap: ${spaces.large2X}px;
        background-color: rgba(0, 0, 0, 0.02);
      `}
  }
`;

const HowSection = ({
  title,
  imageTitle,
  leftImage,
  rightPart,
  isFullRight,
  reverse,
  isLeftMobileView,
}) => {
  return (
    <StyledHowSection>
      {title && <div className="headerSection">{title}</div>}
      <StyledHowContainer reverse={reverse}>
        {leftImage && (
          <StyledHowLeft isLeftMobileView={isLeftMobileView}>
            {imageTitle && <div className="imageHeader">{imageTitle}</div>}
            <picture>
              <source srcSet={leftImage} type="image/svg+xml" />
              <img
                src={leftImage}
                title="Happy business owner pointing Image"
                alt="Happy business owner pointing Image"
              />
            </picture>
          </StyledHowLeft>
        )}
        <StyledHowRight
          isFullRight={isFullRight}
          leftImage={leftImage}
          reverse={reverse}
        >
          {rightPart}
        </StyledHowRight>
      </StyledHowContainer>
    </StyledHowSection>
  );
};

HowSection.propTypes = {
  title: PropTypes.node,
  imageTitle: PropTypes.node,
  leftImage: PropTypes.string,
  rightPart: PropTypes.node,
  isFullRight: PropTypes.bool,
  reverse: PropTypes.bool,
  isLeftMobileView: PropTypes.bool,
};

export default HowSection;
